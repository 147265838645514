var dt = require( 'datatables.net' );

require.context(
    'file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
    true,
    /.*/
);

require( 'bootstrap' );
require( 'datatables.net-bs4' );



import '../css/app.scss';
import '../css/main/app.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'

// Import TinyMCE
import tinymce from 'tinymce/tinymce';



// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';



// A theme is also required
import 'tinymce/themes/silver/theme';


var tinymce_image_field = false;

// Initialize the app
tinymce.init({
    selector: 'textarea',
    // skin: false,
    height : 300,
    plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace code",
        "insertdatetime media table paste"
    ],
    toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
    setup: function(editor) {
        editor.on('BeforeSetContent', function(e) {
            var content = e.content;
            //console.log('BeforeSetContent event', e.content);
            var re = /^<img\s[^>]+>$/i;
            var found = content.match(re);
            if (!found) {
                return true;
            }
            var img = $(e.content);
            var src = $(img).attr('src');
            src = src.replace('/thumb/', '/');
            src = src.replace('/normal/', '/');
            src = src.replace('/small/', '/');
            var link = $('<a target="_blank" class="show_image" href="' + src + '"></a>');
            $(img).appendTo(link);
            var div = $('<div></div>');
            $(link).appendTo(div);
            content = $(div).html();
            e.content = content;
        });
    },
    image_advtab: true,
    images_upload_url: '/admin/upload',
    relative_urls: false
});

$(document).ready( function () {
    $('#datatable').DataTable();

    var $jBody = $('body');

    processTimeSheetsCollection();
    processPicturesCollection();
    processPhonesCollection();
    processLinksCollection();
    processMetrosCollection();
});

function processPicturesCollection()
{
    // setup an "add a tag" link
    var $addPictureLink = $('<a href="#" class="add_picture_link">Добавить изображение</a>');

    // Get the ul that holds the collection of tags
    var $collectionHolder = $('ul.pictures');

    if ($collectionHolder.hasClass('answers')) {
        $addPictureLink = $('<a href="#" class="add_picture_link">Добавить ответ</a>');
    }
    var $newLinkLi = $('<li class="form_add_link"></li>').append($addPictureLink);
    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    // add a delete link to all of the existing tag form li elements
    $collectionHolder.on('click', '.link_remove', function(e) {
        e.preventDefault();
        $(this).closest('li').remove();
    });

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find('li').length - 1);

    $addPictureLink.on('click', function(e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new tag form (see next code block)
        addPictureForm($collectionHolder, $newLinkLi);
    });
}

function processPhonesCollection()
{
    // setup an "add a tag" link
    var $addPhoneLink = $('<a href="#" class="add_picture_link">Добавить телефон</a>');

    // Get the ul that holds the collection of tags
    var $collectionHolder = $('ul.phones');

    var $newLinkLi = $('<li class="form_add_link"></li>').append($addPhoneLink);
    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    // add a delete link to all of the existing tag form li elements
    $collectionHolder.on('click', '.link_remove', function(e) {
        e.preventDefault();
        $(this).closest('li').remove();
    });

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find('li').length - 1);

    $addPhoneLink.on('click', function(e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new tag form (see next code block)
        addPictureForm($collectionHolder, $newLinkLi);
    });
}

function processTimeSheetsCollection()
{
    // setup an "add a tag" link
    var $addPhoneLink = $('<a href="#" class="add_timesheet_link">Добавить Время работы</a>');

    // Get the ul that holds the collection of tags
    var $collectionHolder = $('ul.timeSheets');

    var $newLinkLi = $('<li class="form_add_link"></li>').append($addPhoneLink);
    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    // add a delete link to all of the existing tag form li elements
    $collectionHolder.on('click', '.link_remove', function(e) {
        e.preventDefault();
        $(this).closest('li').remove();
    });

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find('li').length - 1);

    $addPhoneLink.on('click', function(e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new tag form (see next code block)
        addPictureForm($collectionHolder, $newLinkLi);
    });
}

function processLinksCollection()
{
    // setup an "add a tag" link
    var $addPhoneLink = $('<a href="#" class="add_link_link">Добавить Ссылку</a>');

    // Get the ul that holds the collection of tags
    var $collectionHolder = $('ul.links');

    var $newLinkLi = $('<li class="form_add_link"></li>').append($addPhoneLink);
    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    // add a delete link to all of the existing tag form li elements
    $collectionHolder.on('click', '.link_remove', function(e) {
        e.preventDefault();
        $(this).closest('li').remove();
    });

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find('li').length - 1);

    $addPhoneLink.on('click', function(e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new tag form (see next code block)
        addPictureForm($collectionHolder, $newLinkLi);
    });
}

function processMetrosCollection()
{
    // setup an "add a tag" link
    var $addLink = $('<a href="#" class="add_metro_link">Добавить Метро</a>');

    // Get the ul that holds the collection of tags
    var $collectionHolder = $('ul.metros');

    var $newLinkLi = $('<li class="form_add_link"></li>').append($addLink);
    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    // add a delete link to all of the existing tag form li elements
    $collectionHolder.on('click', '.link_remove', function(e) {
        e.preventDefault();
        $(this).closest('li').remove();
    });

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find('li').length - 1);

    $addLink.on('click', function(e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new tag form (see next code block)
        addPictureForm($collectionHolder, $newLinkLi);
    });
}

function addPictureForm($collectionHolder, $newLinkLi) {
    // Get the data-prototype
    var prototype = $collectionHolder.data('prototype');

    // get the new index
    var index = $collectionHolder.data('index');

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newForm = prototype.replace(/__name__/g, index);

    // increase the index with one for the next item
    $collectionHolder.data('index', index + 1);

    // Display the form in the page in an li, before the "Add a tag" link li
    var $newFormLi = $('<li></li>').append(newForm);
    $newLinkLi.before($newFormLi);
}
